import axios from 'axios';

const api = (method, url, variables) => {

  const isFormData = variables instanceof FormData;

    return axios({
      method,
      url: `/api/v1/${url}`,
      params: method !== 'post' ? variables : undefined,
      data: method === 'post' ? variables : undefined,
      headers: {
        'Content-Type': isFormData ? 'multipart/form-data' : 'application/json'
      }
    })
}

export default api;
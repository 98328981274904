import React, { useEffect, useState } from 'react';
import share_icon from './image/share-icon.svg';
import styles from './ShareButton.module.scss';

const ShareButton = ({ id }) => {

    const [isMouseInside, setMouseInside] = useState(false);
    const [host, setHost] = useState('');

    useEffect(() => {
        setHost(window.location.host)
    }, [host, id]);
    const mouseEnter = () => {
        setMouseInside(true)
    };
    const mouseLeave = () => {
        setMouseInside(false)
    };

    return (
        <div className={styles["card-shareable"]}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}>
            <img
                className="card-shareable-image"
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
                src={share_icon}
                alt="share"
                />
            {(isMouseInside) ? (
                <div className={styles["social__container"]} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                    <ul className={styles["social__icons"]}>
                        <li className={styles["social__icons-top"]}><a
                            href={`https://www.facebook.com/sharer/sharer.php?u=www.${host}/accomodation-details?id=${id}`}
                            target="_blank"><i className="fa fa-facebook"></i></a></li>
                        <li className={styles["social__icons-bottom"]}><a
                            href={`https://twitter.com/intent/tweet?text=www.${host}/accomodation-details?id=${id}`}
                            target="_blank"><i className="fa fa-twitter"></i></a></li>
                    </ul>
                </div>
            ) : ('')

            }
        </div>
    )

}

export default ShareButton;
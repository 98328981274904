
import React, { useContext } from 'react';
import Header from '../Header/Header';
import './Layout.scss';
import Footer from '../Footer/Footer';
// import DestinationSlider from '../utils/DestinationsSlider/DestinationsSlider';
import { DestinationContext } from '../../contexts/DestinationContext';

const Layout = ({ children }) => {

  const destination  = useContext(DestinationContext);

  return (
    <div style={{ position: "relative"}}>
      <Header destination={destination} />
      <main>{children}</main>
      {/* <DestinationSlider /> */}
      <Footer />
    </div >
  )
}

export default Layout

import React from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from 'react-leaflet';
import CenterMap from './CenterMap';

const Map = ({ destinations }) => {
  const inlineStyle = {
    fontFamily: 'Helvetica, Arial, sans-serif',
    fontSize: '17px',
    color: 'rgb(90, 97, 105)',
  };

  const renderMarkers = () => {
    return destinations.map(marker => {
      return (
        <Marker position={marker.latlng} key={marker.id}>
          <Popup>
            <p style={inlineStyle}>{marker.name}</p>
          </Popup>
        </Marker>
      );
    });
  };

  const renderPolyLine = () => {
    const latlngs = destinations.map(marker => marker.latlng);

    return (
      <Polyline
        positions={latlngs}
        weight="3"
        dashArray="6"
        dashOffset="6"
        color="#ff9233"
        fillOpacity="0.7"
      />
    );
  };

  return (
    <MapContainer
      center={[51.505, -0.09]}
      zoom={13}
      scrollWheelZoom={false}
      style={{ height: '100%' }}
      closePopupOnClick
    >
      <TileLayer
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        url={`https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png?api_key=${process.env.REACT_APP_LEAFLET_APIKEY}`}
        zIndex={-1}
      />
      <CenterMap markers={destinations} />
      {renderMarkers()}
      {renderPolyLine()}
    </MapContainer>
  );
};

export default Map;

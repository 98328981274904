import React, { useState, useEffect, useContext } from 'react';
import api from '../../../utils/api';
import './index.scss';
import { DestinationContext } from '../../../contexts/DestinationContext';
import isEmpty from 'lodash.isempty';

const RecommendedForYou = ({ pageName }) => {
  const destination= useContext(DestinationContext);

  const incrementClickCount = (id) => {
    api('put','add-advert-click', { id })
      .catch(err => console.error(`An error occured while trying to handle request: ${err.message || 'no message'}`));
  }

  return (
    <React.Fragment>
      {
        !isEmpty(destination.advertising[pageName]) && <div className="reccomendedForyou__main-wrapper">
          <div className="reccomendedForyou__main-content">

            <div className="reccomendedForyou__main-card-content">
              <div className="reccomendedForyou__main-top-content">
                <div className="reccomendedForyou__main-top-content-title">
                  <p>Sponsored links</p>
                </div>
              </div>
              <div className="reccomendedForyou__main-cards-cover">
                {destination.advertising[pageName].map(advert => {
                  return (
                    <div className="reccomendedForyou__main-cards" key={advert._id} onClick={() => incrementClickCount(advert._id)}>
                      <a href={advert.url} target="_blank" rel="noopener noreferrer">
                        <div className="reccomendedForyou__card-top-image"
                          style={{ backgroundImage: `url(${advert.image})` }}>
                          <div className="reccomendedForyou__card-banner">
                            <p>{advert.title}</p>
                          </div>
                        </div>
                      </a>
                    </div>);
                })}
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  )
};

export default RecommendedForYou;
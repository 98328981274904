import React, { useState, createContext } from 'react';

/*
This context provider is needed to programatically open the register window from outside the navigation pane.
*/

export const RegistrationTabContext = createContext();

function RegistrationTabContextProvider({ children }) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <RegistrationTabContext.Provider value={{ isVisible, setIsVisible }}>
      {children}
    </RegistrationTabContext.Provider>
  )
}

export default RegistrationTabContextProvider;

import React, { useState, useEffect, createContext } from 'react';
import api from '../utils/api';
import GoogleAnalytics from 'react-ga';
import fallbackDestination from './fallbackDestination';

/*
This component will download the current destination and provide details of it to other components such as destination id, name, heroImage, description, menu items etc.

If fetching all the data above succeeds, we also initialize Google Analytics.
*/

export const DestinationContext = createContext();

const DestinationContextProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [destination, setDestination] = useState(fallbackDestination);
  const [error, setError] = useState(null);

  useEffect(() => {
    /*
    Here we check which website we are on and continue accordingly. The endpoint check the req.referer to decide which destanation website is calling.
    */

    api('get', `destination-detail`)
      .then(res => {
        setDestination(res.data);
        setIsLoading(false);

        res.data.gaid && GoogleAnalytics.initialize(res.data.gaid);
      })
      .catch(err => {
        /*
        we should consider showing a "sorry under maintenance check back later" message or something like that here...Because if this call fails, the app won't work!!
        */
        setIsLoading(false);
        setError(err);
      });
  }, []);

  return isLoading ? null : (
    <DestinationContext.Provider value={destination}>
      {children}
    </DestinationContext.Provider>
  );
};

export default DestinationContextProvider;

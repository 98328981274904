const navItems = [
  {
    "title": "Home",
    "to": "/",
    "htmlAfter": "",
    "tag": "Link",
    "exact": true
  },
  {
    "title": "Accommodation",
    "to": "/accommodation",
    "icon": "",
    "htmlAfter": "",
    "tag": "Link",
    "exact": false
  },
  {
    "title": "Safaris",
    "to": "/safaris",
    "icon": "",
    "htmlAfter": "",
    "tag": "Link",
    "exact": false
  },
  {
    "title": "Destinations",
    "to": "/destinations",
    "icon": "",
    "htmlAfter": "",
    "tag": "Link",
    "exact": false
  },
  {
    "title": "Useful Info",
    "to": "/useful-info",
    "icon": "",
    "htmlAfter": "",
    "tag": "Link",
    "exact": false
  },
  {
    "title": "About Us",
    "to": "/about-us",
    "htmlAfter": "",
    "tag": "Link",
    "exact": false
  },
  {
    "title": "Shop",
    "to": "http://www.bananabox.shop/",
    "icon": "",
    "htmlAfter": "",
    "tag": "a"
  },
  {
    "title": "Contact Us",
    "to": "/contact",
    "icon": "",
    "htmlAfter": "",
    "tag": "Link",
    "exact": false
  }
];

export default navItems;
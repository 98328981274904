import React from 'react';
import { Link } from 'react-router-dom';
import styles from './InfoSection.module.scss';
import logo from '../../../images/WB_logo_side_brown.png';

const InfoSection = () => (
    <div className={styles.InfoSection}>
        <div className={styles.grid}>
            <div className={styles['wb-text']}>
                <div className={styles['logo-section']}>
                    <img className={styles.logo} src={logo} alt="logo" />
                </div>
                <div className={styles.content}>
                    <p>
                        Wilderness Bookings is a listings platform. We specialise in targeted safari destinations allowing properties and safari operators to display their products to targeted audience.
                    </p>
                    <p>
                        As a result, our users get to contact these companies directly.
                    </p>
                </div>
            </div>
            <div className={styles['quick-links']}>
                <div className={styles['box-title']}>
                    Quicklinks
                </div>
                <div className={styles['quicklinks-menu']}>
                    <ul className={styles['menu-items']}>
                        <li>
                            <Link to="/safaris">Safaris</Link>
                        </li>
                        <li>
                            <Link to="/accommodation">Accommodation</Link>
                        </li>
                        <li>
                            <Link to="/destinations">Destinations</Link>
                        </li>
                        <li>
                            <Link to="/useful-info">Useful Info</Link>
                        </li>
                        <li>
                            <Link to="http://www.bananabox.shop/">Shop</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles['popular-spots']}>
                <div className={styles['box-title']}>
                    Popular Spots
                </div>
                <div className={styles['quicklinks-menu']}>
                    <ul className={styles['menu-items']}>
                        <li>
                            <a href="http://maasaimara.com/" target="_blank" rel="noopener noreferrer"> Maasai Mara </a>
                        </li>
                        <li>
                            <a href="http://amboseli.com/" target="_blank" rel="noopener noreferrer"> Amboseli Park </a>
                        </li>
                        <li>
                            <a href="https://serengetinationalpark.com/" target="_blank" rel="noopener"> Serengeti </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles['contact-us']}>
                <div className={styles['box-title']}>
                    Contact Us
                </div>
                <div className={styles.content}>
                    Please feel free to contact us about anything on this website <br /><br />

                    Wilderness Bookings <br />
                    <a href="mailto:johann@wildernessbookings.com">johann(@)wildernessbookings.com</a> <br />
                    <a href="tel:+254716511162"> +254 (0)716 511162 </a>
                </div>
                <Link to="/contact">
                    <div className={styles['contact-btn']}>
                        Contact Us
                    </div>
                </Link>
            </div>
        </div>
    </div>
)

export default InfoSection

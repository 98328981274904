import React, { Component } from 'react';
import "./scss/style.scss";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terms: false,
    }
  }

  close() {
    this.props.closeTerms(this.state.terms);
  }

  render() {
    return (
      <div>
        <div className="termsconditions-close__form">
          <div className="termsconditions-close">
            <div className="termsconditions-close__wrapper">
              <p className="termsconditions-close__header">Below is our terms and conditions</p>
              <div className="termsconditions-close__words">
                <p>
                  Use of this web site or any linked website (hereinafter together called the “Website”) constitutes your acknowledgement that you have read, understood and agree to, the terms and conditions of use detailed below.
                </p>
                <p>
                  If you do not agree to any part of these terms and conditions, you must not access or use the Website in any way.
                </p>
                <p>
                  By making use of the Website, you agree to be bound by the terms and conditions detailed below:-
                </p>
                <ol>
                  <li>Wilderness Bookings Limited whose management domains name are listed on their website WildernessBookings.com. Below are a few of the domains:-
                    <p>Kenya</p>
                    <ul>
                      <li>MaasaiMara.com</li>
                      <li>Amboseli.com</li>
                      <li>NairobiCity.com</li>
                      <li>TsavoPark.com</li>
                      <li>DianiBeach.info</li>
                    </ul>
                    <p>Tanzania</p>
                    <ul>
                      <li>SerengetiNationalPark.com</li>
                      <li>ZanzibarIsland.com</li>
                      <li>RuahaNationalPark.com</li>
                      <li>TaragireNationalPark.com</li>
                      <li>SelousGameReserve.info</li>
                      <li>MahaleMountains.com</li>
                    </ul>
                    <p>Zambia</p>
                    <ul>
                      <li>SouthLuangwaNationalPark.info</li>
                      <li>LowerZambeziNationalPark.com</li>
                      <li>KafueNationalPark.com</li>
                    </ul>
                    <p>Botswana</p>
                    <ul>
                      <li>MoremiGameReserve.com</li>
                      <li>CentralKalahariNationalPark.com</li>
                      <li>MagkagikadiPansNationalPark.com</li>
                      <li>KalagadiTransfrontierPark.com</li>
                    </ul>
                    <p>(hereinafter together called the “Company” which expression shall include their respective successors, assigns, directors, employees and other officers) accepts no responsibility for errors, omissions, inaccuracies or misleading statements which may appear anywhere on the website, whether or not they were supplied by third party providers.</p>
                  </li>
                  <li>
                    The Company does not warrant, guarantee or make any representation regarding the contents of the Website as to accuracy, non-infringement, correctness, merchantability,
                    completeness or fitness for a particular purpose or otherwise. All warranties, guarantees or representations thereof, either express or implied, are disclaimed. In no circumstances whatsoever shall the Company be liable for any loss, delay, damage, illness, injury or death to you your property or anyone traveling with you or their property or for any claim or any special, punitive or consequential damages arising from any cause in whatever
                    manner howsoever of any kind arising from the use of the Website (hereinafter called
                    “Any Loss”).
                  </li>
                  <li>
                    The Company does not refund payments unless there is a problem with our platform or unless it is mutually agreed.
                  </li>
                  <li>
                    The Company does not warrant the safety, availability or reliability of any safaris, hotel accommodation, diving, fishing, mountain climbing, sightseeing, tours and any other activity related to or incidental to the tours appearing on the Website including but not limited to the transportation, whether by aircraft, rail, road, motor coach, ship, boat, or any other means, and shall not be liable for Any Loss.
                  </li>
                  <li>
                    Notwithstanding anything to the contrary nothing in the Website shall be construed to be an offer to sell or a contract until a booking is confirmed in writing and a deposit has been made.
                  </li>
                  <li>
                    The terms and conditions of use above shall be governed by and construed in accordance with the Laws of Kenya and any action at law or equity shall only be filed in Kenyan courts.
                  </li>
                </ol>
              </div>
            </div>
            <div className="termsconditions-bottom">
              <div className="termsconditions-from__img"></div>
              <div className="termsconditions-from__text">  <a onClick={this.close.bind(this)} className="Close">Close </a></div>
            </div>
          </div>
        </div>
      </div>
    )

  }
}

export default Terms;
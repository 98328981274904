import React from 'react';
import styles from './Map.module.scss';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const LeafletMap = ({ latitude: lat, longitude: lng, zoom, markerData }) => {
  return (
    <MapContainer center={[lat, lng]} zoom={zoom} style={{ height: '100%' }}>
      <TileLayer
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        url={`https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png?api_key=${process.env.REACT_APP_LEAFLET_APIKEY}`}
      />
      {markerData ? (
        markerData.map(marker => {
          return (
            <Marker
              position={[marker.location.latitude, marker.location.longitude]}
              key={marker._id}
            >
              <Popup>
                <div className={styles['info-window']}>
                  <a
                    href={`${window.location.href}accommodation/${marker.slug}`}
                  >
                    <h3>{marker.title}</h3>
                    <img src={marker.photos[0].url} alt="" />
                  </a>
                </div>
              </Popup>
            </Marker>
          );
        })
      ) : (
        <Marker position={[lat, lng]} />
      )}
    </MapContainer>
  );
};

export default LeafletMap;

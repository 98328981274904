import React, { Component, useContext } from "react";
import GoogleAnalytics from "react-ga";

const withTracker = WrappedComponent => {
  return class extends Component {
    componentDidMount() {
      GoogleAnalytics.pageview(window.location.pathname + window.location.search);
    };

    componentDidUpdate() {
      GoogleAnalytics.pageview(window.location.pathname + window.location.search);
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
};

export default withTracker;

import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Link } from "react-router-dom"
import { TweenMax, Expo } from "gsap/all"
import AccountIcon from "../images/icons/account.svg"
import CustomNavItemMobile from "./Menu/mobile-nav";
import navItems from './Menu/navItems';
import { useMediaQuery } from "react-responsive"
import { DestinationLogo } from './DestinationLogo/DestinationLogo';

const ToolBar = (props) => {

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })

  let logoContainer = null;
  let hamburger = null;
  let sidemove = null;
  let sidemoveside = null;
  let menu_mobile = null;

  const [listitems, setList] = useState({})
  const [estado, setEstado] = useState(0)

  useEffect(() => {
    setList(document.querySelectorAll("mobile-menu-ul"))
  }, [])

  const hamburgerTween = () => {
    var node = ReactDOM.findDOMNode(hamburger)

    node.classList.toggle("open")

    let delay_time = 0

    if (estado === 0) {
      TweenMax.to(logoContainer, .5, {
        x: "-.500%",
        ease: Expo.easeInOut,
      })
      TweenMax.to(menu_mobile, .5, {
        display: "block",
        ease: Expo.easeInOut,
      })
      TweenMax.to(sidemove, .5, {
        left: "0",
        ease: Expo.easeInOut,
        delay: 0.1,
      })
      TweenMax.to(sidemoveside, .5, {
        left: "0",
        ease: Expo.easeInOut,
        delay: 0.1,
      })

      listitems.forEach(function () {
        TweenMax.to(listitems, .5, {
          x: "-100%",
          delay: 0,
          ease: Expo.easeInOut,
        })
        delay_time += 0
      })
      setEstado(1)
    } else {
      setEstado(0)
      TweenMax.to(menu_mobile, .5, {
        display: "none",
        ease: Expo.easeInOut,
        delay: .1,
      })
      TweenMax.to(sidemove, .5, {
        left: "100%",
        ease: Expo.easeInOut,
      })
      TweenMax.to(sidemoveside, .5, {
        left: "100%",
        ease: Expo.easeInOut,
      })
      TweenMax.to(logoContainer, .5, {
        x: 0,
        ease: Expo.easeInOut,
      })
      listitems.forEach(function () {
        TweenMax.to(listitems, .5, {
          x: 0,
          display: "none",
          delay: 0,
          ease: Expo.easeInOut,
        })
        delay_time += 0
      })
    }
  }

  return (
    <div className="toolbar__container">
      <div className="toolbar__container-left">
        <div className="toolbar__left"></div>
        <div className="header__logo-section">
          <Link to="/">
            <DestinationLogo splitText={props.logoName} />
          </Link>
        </div>
      </div>
      <div className="toolbar__container-right">
        <div className="toolbar__top-brand"></div>
        {isTabletOrMobile && (
          <div
            id="menu_mobile"
            ref={div => (menu_mobile = div)}
            style={{ display: `none` }}
            id="menu_mobile"
          >
            <div id="bg-menu-mobile" ref={div => (logoContainer = div)}>
              <div className="bg-menu-mobile__mobile-section-wrapper">
                <div className="bg-menu-mobile__mobile-section-wrapper"></div>
                <div className="bg-menu-mobile__mobile-section-wrapper"></div>
              </div>
            </div>
            <div className="bg-menu-mobile__mobile-section-wrapper" onClick={e => hamburgerTween()}>
              <div className="bg-menu-mobile__mobile-section-menu-items">
                <ul className="mobile-menu-ul" ref={div => (sidemove = div)}>
                  {navItems.map((item, idx) => (
                    <CustomNavItemMobile key={idx} item={item} />
                  ))}
                </ul>
                <ul
                  className="mobile-menu-u-2"
                  ref={div => (sidemoveside = div)}
                >
                  {/* <li className="mobile-menu-item">
                    <img src={searchIcon} />
                  </li> */}
                  <li className="mobile-menu-item">
                    <a
                      href={`/`}
                      target="__blank"
                    >
                      <img src={AccountIcon} />
                    </a>
                  </li>
                  {/* <li className="mobile-menu-item">
                    <img src={facebookIcon} />
                  </li>
                  <li className="mobile-menu-item">
                    <img src={twitterIcon} />
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        )}

        <div className="toolbar__right">
          <div
            id="hamburger"
            ref={div => (hamburger = div)}
            onClick={e => hamburgerTween()}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToolBar

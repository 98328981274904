import React from 'react';
import styles from './Statistics.module.scss';
import logo from '../../images/WB_logo_below_brown.png';
import Elephant from '../../images/left-image.png';

const Statistics = () => (
  <div className={styles.Statistics}>
    <div className={styles['animal']}>
      <img
        className={styles['animal__img']}
        src={Elephant}
        alt="elephant image"
      />
    </div>
    <div className={styles['banner-container']}>
      <div className={styles['banner-content-area']}>
        <div className={styles['banner-text']}>
          Over the years, we've worked hard to bring you only the best in the
          wilderness safaris of Africa
        </div>
        <div className={styles['banner-slogan-section']}>
          <div className={styles['banner-slogan']}>Targeted.</div>
          <div className={styles['banner-slogan--bold']}>Unbiased.</div>
          <div className={styles['banner-slogan']}>Direct.</div>
        </div>
      </div>
    </div>
    <div className={styles['content-area-container']}>
      <div className={styles['content-area']}>
        <div className={styles['logo-section']}>
          <img src={logo} alt="logo" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className={styles['counter-section']}>
            <div className={styles['counter-section__field']}>
              <div className={styles['counter-section__field--inner']}>
                <div className={styles['counter-number']}>45,000+</div>
                <div className={styles['counter-title']}>Enquries Sent</div>
                <div className={styles['counter-text']}>
                  To our property owners and registered safaris
                </div>
              </div>
            </div>
            <div className={styles['counter-section__field']}>
              <div className={styles['counter-section__field--inner']}>
                <div className={styles['counter-number']}>13</div>
                <div className={styles['counter-title']}>
                  Years of Operation
                </div>
                <div className={styles['counter-text']}>
                  We've been around for a while & we love what we do
                </div>
              </div>
            </div>
            <div className={styles['counter-section__field']}>
              <div className={styles['counter-section__field--inner']}>
                <div className={styles['counter-number']}>12</div>
                <div className={styles['counter-title']}>Countries</div>
                <div className={styles['counter-text']}>
                  We have presence in 12 countries in Africa
                </div>
              </div>
            </div>
            <div className={styles['counter-section__field']}>
              <div className={styles['counter-section__field--inner']}>
                <div className={styles['counter-number']}>41</div>
                <div className={styles['counter-title']}>Destinations</div>
                <div className={styles['counter-text']}>
                  That you can go to all around our beautiful continent
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Statistics;

import React, { useState, useEffect, useContext } from 'react';
import api from '../../../utils/api';
import styles from './HanpickedSafaris.module.scss';
import { Link } from 'react-router-dom';
import { DestinationContext } from '../../../contexts/DestinationContext';
import EntryCard from '../EntryCard';
import isEmpty from 'lodash.isempty';

const Handpickedsafaris = () => {
  const [safaris, setSafaris] = useState([]);
  const destination = useContext(DestinationContext);

  useEffect(() => {
    api('get', 'safaris/main', { destination: destination._id, limit: 3 }).then(
      response => {
        const { data } = response;
        setSafaris(data);
      }
    );
    /*
     ** Here we are not showing error UI - hence no particular error handling,
     ** instead, we don't render the component if there is nothing to show.
     */
  }, []);

  return (
    !isEmpty(safaris) && (
      <div className={styles.container}>
        <div className={styles['main-wrapper']}>
          <div className={styles['main-content']}>
            <div className={styles['main-top-content']}>
              <div className={styles['main-top-content__title']}>
                <h1>Safaris in the {destination.name}</h1>
                <p>
                  We selected the top 3 best safaris to ensure you experience
                  only the very best in your safari.
                </p>
              </div>
              <div className={styles['main-top-content__clickables']}>
                <div className={styles['clickables']}>
                  <Link
                    to="/safaris"
                    className={styles['main-top-content__tag']}
                  >
                    View All Safaris
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles['main-card-content']}>
              {safaris.map(safari => {
                return (
                  <div key={safari._id}>
                    <EntryCard
                      entry={safari}
                      isProperty={false}
                      isSmall={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Handpickedsafaris;

import React, { Component } from "react"
import Forms from '../registration';
import CustomNavItem from './nav-menu';
import navItems from './navItems';
class Header extends Component {

  render() {
    return (
      <div className="header__container">
        <ul className="header__menu-items">
          {navItems.map((item, idx) => (
            <CustomNavItem key={idx} item={item} />
          ))}
        </ul>
        <ul className="header__menu-items two">
          <li>
            <a href={`/admin`}>Login</a>
          </li>
          <li>
            <p> <Forms /> </p>
          </li>
        </ul>

      </div>
    )
  }
}


export default Header;
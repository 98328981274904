import React, { useEffect, useState, useContext } from 'react'
import styles from './HandpickedAccomodations.module.scss';
import { Link } from 'react-router-dom';
import api from '../../../utils/api';
import { DestinationContext } from '../../../contexts/DestinationContext';
import isEmpty from 'lodash.isempty';
import EntryCard from '../EntryCard';

const PopularAccommodations = () => {

  const [accommodations, setAccommodations] = useState([]);
  const destination = useContext(DestinationContext);

  useEffect(() => {

    api('get', 'property/main', { destination: destination._id, limit: 3 })
      .then(response => {
        const { data } = response;
        setAccommodations(data);
      });

    /* 
    ** Here we are not showing error UI - hence no particular error handling, ** instead, we don't render the component if there is nothing to show.
    */
  }, [])

  return !isEmpty(accommodations) && (
    <div className={styles['handpicked-accomodations']}>
      <div className={styles["main-wrapper"]}>
        <div className={styles["main-content"]}>
          <div className={styles["main-top-content"]}>
            <div className={styles["main-top-content-title"]}>
              <h1>Accommodation in {destination.name}</h1>
              <p>We hand-picked best property to ensure you experience the best in your stay.
              </p>
            </div>
            <div className={styles["main-top-content-clickables"]}>
              <div className={styles["clickables"]}>
                <Link to="/accommodation" className={styles["main-top-content-tag"]}>
                  View All {destination.name} Properties </Link>
              </div>
            </div>
          </div>
          <div className={styles["main-card-content"]}>
            <div className={styles["main-cards-cover"]}>
              {accommodations.map((accommodation, i) => {
                return <EntryCard
                  key={i}
                  entry={accommodation}
                  isProperty={true}
                  isSmall={true}
                />
              })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};


export default PopularAccommodations;
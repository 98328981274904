export const getIsVerified = owner => {
  if (!owner?._id) return false;

  const { _id } = owner;

  const ordId =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_ORD_ID_DEV
      : process.env.REACT_APP_ORD_ID_PROD;

  return _id !== ordId;
};

export const isAdminOwned = id => {
  const ordId =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_ORD_ID_DEV
      : process.env.REACT_APP_ORD_ID_PROD;

  return id === ordId;
};

export const getCompanyNameForEnquiryThankYouNote = companyName => {
  const regexp = /ord group/i;

  return regexp.test(companyName) || !companyName
    ? 'Wilderness Bookings Limited'
    : companyName;
};

import { useMap } from 'react-leaflet';
import isEmpty from 'lodash.isempty';

const CenterMap = ({ markers }) => {
  const map = useMap();

  if (isEmpty(markers)) return null;

  const bounds = markers.map(d => d.latlng);

  map.fitBounds(bounds);

  return null;
};

export default CenterMap;

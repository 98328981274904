import React from 'react';
import PropertyEntryCard from './PropertyEntryCard';
import SafariEntryCard from './SafariEntryCard';

/*
This is the component which displays an Entry object, be it a safari or a property.
*/

function EntryCard({ entry, isProperty, isSmall }) {
  if (isProperty) {
    return <PropertyEntryCard entry={entry} isSmall={isSmall} />;
  } else {
    return <SafariEntryCard entry={entry} isSmall={isSmall} />;
  }
}

export default EntryCard;

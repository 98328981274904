import React, { Component } from 'react';
import wbfav from './assets/images/wb-fav.png';
import pattern from './assets/images/pattern.png';

class Banner extends Component {
render(){
    return (
           <div className="modal-main__banner">
                    <div className="modal-main__banner-first">
                        <span className="modal-main__banner-first-img"><img  src={wbfav} alt="top banner"></img></span>
                            <p className="modal-main__banner-first-h3">Wilderness</p>
                                    <a href="£"><span className="modal-main__banner-first-a">bookings.com</span></a>
                    </div>
                    <div className="modal-main__banner-second">
                        <span className="modal-main__banner-second-img"><img src={pattern}  alt="pattern"></img></span>
                    </div>
            </div>
      
            )
          
        }
    }

export default Banner;
import React, { useState, useContext } from 'react';
import { Modal} from 'react-bootstrap';
import './scss/style.scss';
import TabsModal from './Tabs';
import close from './assets/images/menu-close-icon.svg';
import {RegistrationTabContext} from '../../contexts/RegistrationTabContext';

function App() {
  const {isVisible, setIsVisible} = useContext(RegistrationTabContext);

  return (
    <>
      <button className="open-modal-btn" onClick={() => setIsVisible(true)}>
        Create Account
      </button>
      <Modal
        show={isVisible}
        onHide={() => setIsVisible(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className="modal-header">
          <h3>User Registration</h3>
          <span className="close-modal-btn">
            <img src={close} onClick={() => setIsVisible(false)} alt="close"></img>
          </span>
        </div>
        <Modal.Body className="registration-modal">
          <div className="modal-main">
            <TabsModal setModalOpen={setIsVisible} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default App

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import React, { Component } from 'react';
import SafariOwner from "./safariOwner/components/SafariOwner";
import "./scss/style.scss";
import PropertyOwner from './propertyOwner/components/PropertyOwner';

class TabsModal extends Component {
    render() {
        return (
            <Tabs>
                <TabList>
                    <Tab><p className="title-owner">Property Owner</p></Tab>
                    <Tab><p className="title-owner">Safari owner</p></Tab>
                </TabList>
                <TabPanel>
                    <PropertyOwner setModalOpen={this.props.setModalOpen} />
                </TabPanel>
                <TabPanel>
                    <SafariOwner setModalOpen={this.props.setModalOpen}/>
                </TabPanel>
            </Tabs>
        );
    }
}

export default TabsModal;
import React from "react";
import PropTypes from "prop-types";
import Svg from 'react-inlinesvg';
import { NavLink } from "react-router-dom";

const selectedStyle = {
  color: '#d8be8d',
  borderBottom: '1px solid',
  paddingBottom: '10px'
};

class CustomNavItem extends React.Component {

  render() {
    const { item } = this.props;
    return (
      <li>
        {
          (item.tag === 'a') ? (
            <a href={item.to} target='_blank' rel="noopener noreferrer">
              {item.icon && (
                <Svg wrapper={React.createFactory('div')} className="d-inline-block item-icon-wrapper" src={require('../../images/icons/' + item.icon)} />
              )}
              {item.title && item.title}
            </a>
          ) : (
            <NavLink to={item.to} activeStyle={selectedStyle} exact={item.exact}>
              {item.icon && (
                <Svg wrapper={React.createFactory('div')} className="d-inline-block item-icon-wrapper" src={require('../../images/icons/' + item.icon)} />
              )}
              {item.title && item.title}
            </NavLink>
          )
        }
      </li>
    );
  }
}

CustomNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default CustomNavItem;



import React, { useRef, useEffect } from 'react';
import Head from '../Menu/header';
import ToolBar from '../toolbar';
import Sticky from 'react-stickynode';
import styles from './Header.module.scss';
import { useMediaQuery } from "react-responsive";
import navItems from '../Menu/navItems'


function Header({ destination }) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1250px)" })

  const largeScreenHeader = (
    <Sticky innerZ={1000}>
      <div className={styles.header}>
        <ToolBar navItems={navItems} logoName={destination.logoName}/>
        <Head navItems={navItems} />
      </div>
    </Sticky>
  );

  const mediumScreenHeader = (
    <div className={styles.header}>
      <ToolBar navItems={navItems} logoName={destination.logoName}/>
      <Sticky innerZ={1000}>
        <Head navItems={navItems} />
      </Sticky>
    </div>
  );

  return (
    <header >
        {isTabletOrMobile
          ? mediumScreenHeader
          : largeScreenHeader
        }
    </header>
  )
}

export default Header

import React from 'react';
import { Link } from 'react-router-dom';
import styles from './EntryCard.module.scss';
import BookDirect from '../BookDirect';
import ToolTipIcon from '../ToolTip/ToolTip';
import ListIcon from '../../../images/icons/list-icon.svg';
import ShareButton from '../ShareButton/ShareButton';
import SafariMap from '../Maps/SafariMap/SafariMap';
import { getIsVerified } from '../../../helpers';

/*
This is the component used for safari entries, it displays dfferent data from the SafariInner.
*/

function SafariInner({ entry }) {
  return (
    <React.Fragment>
      <div className={styles.top}>
        <div className={styles['card__top']}>
          <h3 className={styles.company}>{entry.owner.companyName}</h3>
          <h3 className={styles.title}>{entry.titleName}</h3>
        </div>
        <div className={styles['top__logo']}>
          {entry.logo ? (
            <img src={entry.logo} alt={`${entry.title} logo`} />
          ) : entry.owner.logo ? (
            <img src={entry.owner.logo} alt={`${entry.title} logo`} />
          ) : null}
        </div>
      </div>
      <div className={styles.location}>
        <p>{entry.startLocation}</p>
        <img src={ListIcon} alt="List Icon" className={styles['list-icon']} />
        <p>{entry.endLocation}</p>
        <div className={styles.share}>
          <ShareButton id={entry._id} />
        </div>
      </div>
      <p className={styles.description}>{entry.briefDescription}</p>
      <div className={styles.bottom}></div>
    </React.Fragment>
  );
}

function SafariEntryCard({ entry, isSmall }) {
  const prefix = '/safaris';
  /*
  Check out if entry is safari or property and assign inner content accordingly.
  */
  const isVerified = getIsVerified(entry.owner);

  return (
    <div
      className={
        isSmall
          ? styles['card-small']
          : `${styles.card} ${styles['card-safari']}`
      }
    >
      <Link to={`${prefix}/${entry.slug}`}>
        <div className={isSmall ? styles['col-1-small'] : styles['col-1']}>
          <div className={styles['image-container']}>
            <img src={entry.photos[0].url} alt={entry.photos[0].title} />
            {isVerified ? (
              <div className={styles.tooltip}>
                <ToolTipIcon isSmall={true} />
              </div>
            ) : null}
          </div>
          <div
            className={
              isSmall
                ? styles['book-small']
                : `${styles.book} ${styles['book-safari']}`
            }
          >
            {isVerified ? <BookDirect /> : null}
          </div>
        </div>
      </Link>
      <Link to={`${prefix}/${entry.slug}`}>
        <div className={isSmall ? styles['col-2-small'] : styles['col-2']}>
          <SafariInner entry={entry} />
        </div>
      </Link>
      <div className={isSmall ? styles['col-3-small'] : styles['col-3']}>
        {/*TODO: This map displays only one marker, for safaris we will need to replace it when it is available*/}
        <SafariMap destinations={entry.destinations} />
      </div>
    </div>
  );
}

export default SafariEntryCard;

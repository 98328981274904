import React, { Component } from 'react';
import "./scss/style.scss";

class Close extends Component {

  render() {
    const message = this.props.error
      ? <div>
        <p>We could not follow through with your registration because the following error occured:</p>
        <p className="error">{this.props.error}</p>
        <p>Please try again or contact a website admin if you are stuck - we will be happy to help. Thank you.
        </p>
      </div>
      : <p>
        <span className="text-highlight">Thank you for registering</span> on our website! You’ll receive an email asking you to <span className="text-highlight">confirm your email address</span>. Please make sure you do this <span className="text-highlight">in the next hour</span>-as the link you will receive is only valid for one hour.
        </p>;

    return (
      <div>
        <div className="registration-close__form">
          <div className="registration-close">
            <div className="registration-close__wrapper">
              <div className="registration-close__words">
                <div className="registration-close__words-p">
                  {message}
                </div>
              </div>
            </div>
            <div className="registration-bottom">
              {/* <div className="registration-from__img"></div> */}
              <div className="registration-from__text">
                <a onClick={this.props.close} className="Close">
                  Close
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Close;
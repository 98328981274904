import React, { useContext, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Intro from '../Intro';
import Statistics from '../Statistics/Statistics';
import HandPickedAccommodations from '../utils/HandpickedAccomodations/HandpickedAccomodations';
import HandPickedSafaris from '../utils/HandpickedSafaris/HandpickedSafaris';
import RecommendedForYou from '../utils/RecommendedForYou/RecommendedForYou';
import { DestinationContext } from '../../contexts/DestinationContext';
import PageHero from '../utils/PageHero/PageHero';

const HomePage = () => {
  const destination = useContext(DestinationContext);

  const heroData = {
    header: destination.mainHeader,
    description: destination.mainSub,
    images: destination.images.home,
  };

  const fallbackMetaData = {
    title: `${destination.name} | Book Accommodation and Safaris Directly`,
    description: `Find your preferred places to stay or your dream safari in ${destination.name} and contact them directly. We support direct bookings where you are in charge. Contact properties and safari operators directly.`,
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          {destination.metaData?.homePage?.title || fallbackMetaData.title}
        </title>
        <meta
          name="description"
          content={
            destination.metaData?.homePage?.description ||
            fallbackMetaData.description
          }
        />
      </Helmet>
      <PageHero data={heroData} />
      <RecommendedForYou pageName="homePage" />
      <Intro />
      <div>
        <HandPickedAccommodations />
        <HandPickedSafaris currentDestination={destination.name} />
      </div>
      <Statistics />
    </Fragment>
  );
};

export default HomePage;

const fallbackDestination = {
  _id: '5f7b5003cc5a1c0850bf3b73',
  name: 'Amboseli National Park',
  country: '5f7b5003cc5a1c0850bf3b71',
  sbaffurl: '/tours/amboseli',
  tagLine: 'Home to the giants. Rift Valley. Kenya.',
  logoName: ['Amboseli.com', 'Kenya'],
  url: 'www.amboseli.com',
  location: {
    latitude: -2.652845109518905,
    longitude: 37.24388986455079
  },
  description:
    'Amboseli National Park is located in Loitoktok District, Rift Valley Province of Kenya.The park is 39, 206 hectares(392 km2; 151 sq.mi) in size at the core of an 8, 000 square kilometres(3,100 sq.mi) ecosystem that spreads across the Kenya - Tanzania border.The park is famous for being the best place in Africa to get close to   free-ranging elephants among other wildlife species. Other attraction   of the park includes opportunities to meet the Maasai people and also    offers spectacular views of Mount Kilimanjaro, the highest     free-standing mountain in the world.'
  ,
  images: {
    home: [
      {
        title: 'Pic 1',
        url: `${process.env.REACT_APP_BASE_URL}/images/elephants.jpg`,
        credits: 'ORD Group'
      },
      {
        title: 'Pic 2',
        url: `${process.env.REACT_APP_BASE_URL}/images/safari-hero.jpg`,
        credits: 'ORD Group'
      },
      {
        title: 'Pic 3',
        url: `${process.env.REACT_APP_BASE_URL}/images/safari-hero.jpg`,
        credits: 'ORD Group'

      }
    ],
    accommodation: [{
      title: 'Pic 4',
      url: `${process.env.REACT_APP_BASE_URL}/images/accomodation-hero.jpg`,
      credits: 'ORD Group'
    }],
    safari: [{
      title: 'Pic 5',
      url: `${process.env.REACT_APP_BASE_URL}/images/safari-hero.jpg`,
      credits: 'ORD Group'
    }],
    contact: [{
      title: 'Pic 6',
      url: `${process.env.REACT_APP_BASE_URL}/images/contact-hero.jpg`,
      credits: 'ORD Group'
    }],
    about: [{
      title: 'Pic 7',
      url: `${process.env.REACT_APP_BASE_URL}/images/amboseli.jpg`,
      credits: 'ORD Group'
    }],
    destination: [{
      title: 'Pic 8',
      url: `${process.env.REACT_APP_BASE_URL}/images/contact-hero.jpg`,
      credits: 'ORD Group'
    }],
    info: [{
      title: 'Pic 9',
      url: `${process.env.REACT_APP_BASE_URL}/images/hero-about2.jpg`,
      credits: 'ORD Group'
    }],
  },
  advertising: {
    homePage: [
      {
        _id: '5f61e28a70bdbf3f18b04b1b',
        title: 'African Safaris',
        image: `${process.env.REACT_APP_BASE_URL}/images/safari-hero.jpg`,
        url: 'https://www.bespokesafaris.com/',
      },
      {
        _id: '5f61e28a70bdbf3f18b04b1c',
        title: 'Great Rift Valley Lodge',
        image: `${process.env.REACT_APP_BASE_URL}/images/accomodation-hero.jpg`,
        url: 'https://www.bespokesafaris.com/',
      },
      {
        _id: '5f61e28a70bdbf3f18b04b1d',
        title: 'Mama Lucy\'s Lodge',
        image: `${process.env.REACT_APP_BASE_URL}/images/safari_tent.jpg`,
        url: 'https://www.bespokesafaris.com/',
      }
    ],
    safariPage: [
      {
        _id: '5f61e28a70bdbf3f18b04b19',
        title: 'Bespoke Safaris',
        image: `${process.env.REACT_APP_BASE_URL}/images/amboseli.jpg`,
        url: 'https://www.bespokesafaris.com/',
      },
      {
        _id: '5f61e28a70bdbf3f18b04b1a',
        title: 'Go Safaris',
        image: `${process.env.REACT_APP_BASE_URL}/images/single-safari-hero.jpg`,
        url: 'https://www.bespokesafaris.com/',
      }
    ],
    accommodationPage: [
      {
        _id: '5f61e28a70bdbf3f18b04b1d',
        title: 'Mama Lucy\'s Lodge',
        image: `${process.env.REACT_APP_BASE_URL}/images/safari_tent.jpg`,
        url: 'https://www.bespokesafaris.com/',
      }
    ]
  }
};

export default fallbackDestination;
import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import routes from "./routes";
import DestinationContextProvider from './contexts/DestinationContext';
import RegistrationTabContextProvider from './contexts/RegistrationTabContext';
import "bootstrap/dist/css/bootstrap.min.css";
// import './fonts/stylesheet.css';
import Layout from './components/Layout/Layout';
import withTracker from "./withTracker";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  return (
    <DestinationContextProvider>
      <RegistrationTabContextProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Layout>
            <Switch>
              {routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    //TODO: wrap the component with withTracker
                    component={withTracker(route.component)}
                  />
                );
              })}
            </Switch>
          </Layout>
        </BrowserRouter>
      </RegistrationTabContextProvider>
    </DestinationContextProvider>
  )
}
export default App;

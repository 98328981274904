import React, { Fragment } from 'react';
import loadable from '@loadable/component';

const AccomodationList = loadable(() => import('../components/AccomodationList/AccomodationList'));

const AccomodationsPage = () => (
    <Fragment>
        <AccomodationList />
    </Fragment>
)

export default AccomodationsPage
import React, { useState, useEffect, useContext } from 'react';
import api from '../../utils/api';
import styles from './Intro.module.scss';
import { DestinationContext } from '../../contexts/DestinationContext';
import LeafletMap from '../utils/Maps/LeafletMap';
import DOMPurify from 'dompurify';

const Intro = () => {
  const destination = useContext(DestinationContext);
  const [markerData, setMarkerData] = useState([]);
  // const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    api('get', 'mapData').then(res => setMarkerData(res.data));
  }, []);

  // const toggleOpen = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <div className={styles.container}>
      <p className={styles.text}>Welcome to</p>
      <h2 className={styles.title}>{destination.name}</h2>
      <h3 className={styles.subtitle}>{destination.tagLine}</h3>
      {/* <div className={
        isOpen
          ? `${styles.content}`
          : `${styles.content} ${styles['content-closed']}`
      }> */}
      <div className={styles.content}>
        <div className={styles.map} id="map">
          <LeafletMap
            longitude={destination.location.longitude}
            latitude={destination.location.latitude}
            markerData={markerData}
            zoom={10}
          />
        </div>
        <div
          className="inserted-html"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(destination.description),
          }}
        ></div>
      </div>
      {/* <button type="button" className={styles.btn} onClick={toggleOpen}>
        {
          isOpen
            ? 'Show Less'
            : 'Show More'
        }
      </button> */}
    </div>
  );
};

export default Intro;

import React from 'react';
import styles from './BookDirect.module.scss';
import BookIcon from '../../../images/icons/book-direct.png';

function BookDirect() {
  return (
    <div className={styles.cta}>
      <img src={BookIcon} alt="Book direct" />
    </div>
  )
}

export default BookDirect

import * as Yup from 'yup';

const validationSchema = Yup.object({
  name: Yup.string()
    .test('onlyAplhabetical', 'This field can only contain letters.', function (value) {
      return /^[a-zA-Z\s]*$/.test(value);
    })
    .max(40, 'Name must be shorter than 40 characters')
    .required('Name is required'),
  companyName: Yup.string()
    .max(50, 'Company name must be shorter than 50 characters')
    .required('Company name is required'),
  tradingName: Yup.string()
    .max(50, 'Trading name must be shorter than 50 characters'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  position: Yup.string()
    .max(50, 'Position must be shorter than 50 characters')
    .required('Position is required'),
  password: Yup.string()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{6,}$/, 'Minimum 6 characters, at least one uppercase letter, one lowercase letter and one number')
    .required('Password is required'),
  propertyOwnerLogo: Yup.mixed()
    .nullable()
    .required('Company logo is required')
    .test('fileType', 'Unsupported File Type. Supported types: .png, .jpg, .jpeg.', value => {
      if (value) {
        const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
        return SUPPORTED_FORMATS.includes(value.type)
      }
    })
    .test('fileSize', "File size is too large. Maximum size 1.5MB.", value => {
      if (value) {
        const sizeInBytes = 1500000;//1.5MB
        return value.size <= sizeInBytes;
      }
    }),
  certOfIncorporation: Yup.mixed()
    .nullable()
    .required('Certificate of incorporation is required')
    .test('fileType', 'Unsupported File Type. Supported types: .png, .jpg, .jpeg.', value => {
      if (value) {
        const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
        return SUPPORTED_FORMATS.includes(value.type)
      }
    })
    .test('fileSize', "File size is too large. Maximum size 1.5MB.", value => {
      if (value) {
        const sizeInBytes = 1500000;//1.5MB
        return value.size <= sizeInBytes;
      }
    }),
  termsAccepted: Yup.bool()
    .test('termsAccepted', 'Please accept our terms and conditions', value => value)
})

export default validationSchema;
import React, { Fragment } from 'react';
import styles from './DestinationLogo.module.scss';

export const DestinationLogo = ({ splitText }) => {
  return (
    <Fragment>
      {
        splitText.length === 2 && (
          <div className={styles.container}>
            <p className={styles.top}>{splitText[0]}</p>
            <p className={styles.bottom}>{splitText[1]}</p>
          </div>
        )
      }
      {
        splitText.length === 3 && (
          <div className={styles.container}>
            <p className={styles.top}>{splitText[0]}</p>
            <p className={styles.middle}>{splitText[1]}</p>
            <p className={styles.bottom}>{splitText[2]}</p>
          </div>
        )
      }
    </Fragment>
  )
};

export const DestinationLogoLight = ({ splitText }) => {
  return (
    <Fragment>
      {
        splitText.length === 2 && (
          <div className={styles.container}>
            <p className={styles['top-light']}>{splitText[0]}</p>
            <p className={styles['bottom-light']}>{splitText[1]}</p>
          </div>
        )
      }
      {
        splitText.length === 3 && (
          <div className={styles.container}>
            <p className={styles['top-light']}>{splitText[0]}</p>
            <p className={styles['middle-light']}>{splitText[1]}</p>
            <p className={styles['bottom-light']}>{splitText[2]}</p>
          </div>
        )
      }
    </Fragment>
  )
};

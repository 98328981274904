import React from 'react';
import styles from './PageHero.module.scss';
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';

const PageHero = ({ data }) => {
  const isMultipleImages = data.images.length > 1;

  const opts = {
    width: '100%',
    height: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      loop: 1,
      mute: 1,
      modestbranding: 1,
      fs: 0,
    },
  };

  const youTubeVideoId = data.youTubeUrl ? data.youTubeUrl.split('=')[1] : '';

  return (
    <div className={styles['PageHero']}>
      <div className={styles.carousel}>
        {youTubeVideoId ? (
          <YouTube
            className={styles.carousel}
            videoId={youTubeVideoId}
            opts={opts}
          />
        ) : (
          <Carousel
            controls={false}
            indicators={isMultipleImages}
            interval={null}
          >
            {data.images.map(image => {
              return (
                <Carousel.Item key={image._id} style={{ height: '100vh' }}>
                  <img
                    className={styles['feature-image']}
                    src={image.url}
                    alt={image.title}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        )}
      </div>
      <div className={styles.content}>
        <h2 className={styles.header}>
          <b>{data.header}</b>
        </h2>
        <p className={styles.description}>{data.description}</p>
      </div>
    </div>
  );
};

export default PageHero;

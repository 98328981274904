import React, { useContext } from 'react';
import styles from './Copyright.module.scss';
import { DestinationContext } from '../../../contexts/DestinationContext';
import InstagramIcon from '../../../images/instagram-icon.svg';
import FbIcon from '../../../images/facebook-icon.svg';
import TwitterIcon from '../../../images/twitter-icon.svg';

const Copyright = () => {
  const destination = useContext(DestinationContext);

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getHostName = url => {
    const hostName = new URL(url).hostname;

    return hostName.includes('www.')
      ? capitalizeFirstLetter(hostName.split('www.')[1])
      : capitalizeFirstLetter(hostName);
  };

  return (
    <div className={styles.Copyright}>
      <div className={styles.left}>
        © All rights reserved. {getHostName(destination.url)} managed by{' '}
        <span className={styles.bookings}>WildernessBookings.com</span>
      </div>
      <div className={styles.right}>
        <span className={styles['get-social']}>Get Social</span>
        <div className={styles['social-icons']}>
          <a href="https://www.instagram.com/" target="_blank" rel="noopener">
            <img className={styles.icon} src={InstagramIcon} alt="Instagram" />
          </a>
          <a href="https://facebook.com" target="_blank" rel="noopener">
            <img className={styles.icon} src={FbIcon} alt="Facebook" />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener">
            <img className={styles.icon} src={TwitterIcon} alt="Twitter" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Copyright;

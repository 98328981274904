import React from 'react';
import { Link } from 'react-router-dom';
import styles from './EntryCard.module.scss';
import BookDirect from '../BookDirect';
import ToolTipIcon from '../ToolTip/ToolTip';
import ListIcon from '../../../images/icons/list-icon.svg';
import ShareButton from '../ShareButton/ShareButton';
import LeafletMap from '../Maps/LeafletMap';
import { getIsVerified } from '../../../helpers';

/*
This is the component used for property entries, it displays dfferent data from the SafariInner.
*/

function PropertyInner({ entry }) {
  const isVerified = getIsVerified(entry.owner);

  return (
    <React.Fragment>
      <div className={styles.top}>
        <div className={styles['card__top']}>
          <div className={styles['card__top-top']}>
            <div className={styles.badge}>{entry.classification}</div>
            <h3 className={styles['property-type']}>
              {entry.propertyType.name}
            </h3>
          </div>
          <h3 className={styles.title}>{entry.title}</h3>
        </div>
        <div className={styles['top__logo']}>
          {entry.logo ? (
            <img src={entry.logo} alt={`${entry.title} logo`} />
          ) : isVerified && entry.owner.logo ? (
            <img src={entry.owner.logo} alt={`${entry.title} logo`} />
          ) : null}
        </div>
      </div>
      <div className={styles.location}>
        <p>{entry.visibleOn.country.name}</p>
        <img src={ListIcon} alt="List Icon" className={styles['list-icon']} />
        <p>{entry.visibleOn.name}</p>
        <div className={styles.share}>
          <ShareButton id={entry._id} />
        </div>
      </div>
      <p className={styles.description}>{entry.briefDescription}</p>
      <div className={styles.bottom}>
        <p className={styles.rooms}>
          {`This property has 
          ${entry.rooms}
          ${entry.rooms !== 1 ? 'rooms.' : 'room.'}`}
        </p>
      </div>
    </React.Fragment>
  );
}

function PropertyEntryCard({ entry, isSmall }) {
  const prefix = '/accommodation';
  /*
  Check out if entry is safari or property and assign inner content accordingly.
  */
  const isVerified = getIsVerified(entry.owner);

  return (
    <div className={isSmall ? styles['card-small'] : styles.card}>
      <Link to={`${prefix}/${entry.slug}`}>
        <div className={isSmall ? styles['col-1-small'] : styles['col-1']}>
          <div className={styles['image-container']}>
            <img src={entry.photos[0].url} alt={entry.photos[0].title} />
            {isVerified ? (
              <div className={styles.tooltip}>
                <ToolTipIcon isSmall={true} />
              </div>
            ) : null}
          </div>
          <div className={isSmall ? styles['book-small'] : styles.book}>
            {isVerified ? <BookDirect /> : null}
          </div>
        </div>
      </Link>
      <Link to={`${prefix}/${entry.slug}`}>
        <div className={isSmall ? styles['col-2-small'] : styles['col-2']}>
          <PropertyInner entry={entry} />
        </div>
      </Link>
      <div className={isSmall ? styles['col-3-small'] : styles['col-3']}>
        {/*TODO: This map displays only one marker, for safaris we will need to replace it when it is available*/}
        <LeafletMap
          longitude={entry.location.longitude}
          latitude={entry.location.latitude}
          zoom={8}
        />
      </div>
    </div>
  );
}

export default PropertyEntryCard;

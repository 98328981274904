import React from "react";
import PropTypes from "prop-types";
import Svg from 'react-inlinesvg';
import { Link } from "react-router-dom";


class CustomNavItemMobile extends React.Component {

    render() {
        const { item } = this.props;
        return (
            <>
                <li className="mobile-menu-item">
                    <Link to={item.to}>
                        {item.icon && (
                            <Svg wrapper={React.createFactory('div')} className="d-inline-block item-icon-wrapper" src={require('../../images/icons/' + item.icon)} />
                        )}
                        {item.title && item.title}
                    </Link>
                </li>

            </>
        );
    }
}

CustomNavItemMobile.propTypes = {
    /**
     * The item object.
     */
    item: PropTypes.object
};

export default CustomNavItemMobile;



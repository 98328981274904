import React, { useState, useEffect } from 'react';
import Banner from "../../Banner";
import loader from "../../assets/images/Spinner.svg";
import showpwb from "../../assets/images/shape.svg";
import showpass from "../../assets/images/show-password.svg";
import Close from "../../Close";
import "../../scss/style.scss";
import Terms from '../../Terms';
import validationSchema from '../ValidationSchema';
import api from '../../../../utils/api';

function SafariOwner(props) {

  const [loading, setLoading] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [error, setError] = useState(null);
  const [terms, setTerms] = useState(false);
  const [values, setValues] = useState({
    companyName: '',
    tradingName: '',
    safariOwnerLogo: null,
    certOfIncorporation: null,
    affiliateLogo: null,
    affiliateLink: '',
    affiliateName: '',
    name: '',
    position: '',
    email: '',
    password: '',
    termsAccepted: false
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    const currentErrors = { ...validationErrors };

    for (let fieldName in values) {
      validationSchema.validateAt(fieldName, values)
        .then(res => {
          if (currentErrors[fieldName]) {
            delete currentErrors[fieldName];
          }
        })
        .catch(err => {
          Object.assign(currentErrors, { [fieldName]: err.errors[0] });
        });
    }

    setValidationErrors(currentErrors);
  }, [values]);

  useEffect(() => {
    const currentErrors = { ...validationErrors };

    for (let fieldName in values) {
      validationSchema.validateAt(fieldName, values)
        .then(res => {
          if (currentErrors[fieldName]) {
            delete currentErrors[fieldName];
          }
        })
        .catch(err => {
          Object.assign(currentErrors, { [fieldName]: err.errors[0] });
        });
    }

    setValidationErrors(currentErrors);
  }, [touched]);

  const toggleInputType = () => {
    const type = inputType === "password" ? "input" : "password";
    setInputType(type);
  };

  const setTerm = terms => {
    setTerms(terms);
    setOpen(true);
  };

  const closeMessage = () => {
    // setOpen(true);
    props.setModalOpen(false);
    setFeedbackOpen(false);
    setError(null);
  };

  const showTerms = e => {
    e.preventDefault()
    setTerms(true)
    setOpen(false)
  };

  const handleFieldChange = (value, fieldName) => {
    setValues({
      ...values,
      [fieldName]: value
    });
  };

  const handleFieldTouched = fieldName => {
    setTouched({
      ...touched,
      [fieldName]: true
    })
  };

  const setAllTouched = fields => {
    const all = {};

    fields.forEach(field => {
      all[field] = true;
    });

    setTouched({ ...all });
  };

  const handleSubmit = e => {
    e.preventDefault();

    setAllTouched(Object.keys(values));

    if (!validationSchema.isValidSync(values)) return;

    setLoading(true);

    const formData = new FormData();
    const { safariOwnerLogo, certOfIncorporation, affiliateLogo } = values;

    const jsonBody = {
      userName: values.name,
      email: values.email,
      password: values.password,
      companyName: values.companyName,
      tradingName: values.tradingName,
      position: values.position,
      role: 'safari-owner',
      affiliateName: values.affiliateName,
      affiliateLink: values.affiliateLink,
    };

    for (let field in jsonBody) {
      formData.append(field, jsonBody[field]);
    }

    formData.append('logo', safariOwnerLogo);
    formData.append('certOfIncorporation', certOfIncorporation);
    formData.append('affiliateLogo', affiliateLogo);


    api('post', 'register', formData)
      .then(response => {
        setFeedbackOpen(true);
        setOpen(false);
        setLoading(false);
      })
      .catch(err => {
        if (err.response.status === 500) {
          setError('Server error');
        } else {
          setError(err.response.data.message);
        }
        setFeedbackOpen(true);
        setOpen(false);
        setLoading(false)
      })
  }


  return (
    <div className="wrap-box">
      {loading &&
        <div className="back-drops"></div>}
      {loading &&
        <div className="back-drops2">
          <img src={loader} className="img-loader" alt="hey,hh" />
        </div>}
      <Banner></Banner>
      {open && (
        <form className="registration-form__form" onSubmit={handleSubmit}>
          <div className="registration-form">
            <div className="registration-form__first">
              <div className="registration-form__wb-field">
                <label htmlFor="companyName">Company Name <span className="req">*</span>
                </label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={values.companyName}
                  onChange={e => handleFieldChange(e.target.value, 'companyName')}
                  onBlur={() => handleFieldTouched('companyName')}
                />
                {
                  validationErrors.companyName && touched.companyName
                    ? <p className="errors">{validationErrors.companyName}</p>
                    : null
                }
              </div>

              <div className="registration-form__wb-field" >
                <label htmlFor="tradingName">Trading Name</label>
                <input
                  type="text"
                  id="tradingName"
                  name="tradingName"
                  value={values.tradingName}
                  onChange={e => handleFieldChange(e.target.value, 'tradingName')}
                  onBlur={() => handleFieldTouched('tradingName')}
                  placeholder="If different from company name"
                />
                {
                  validationErrors.tradingName && touched.tradingName
                    ? <p className="errors">{validationErrors.tradingName}</p>
                    : null
                }
              </div>

              <div className="registration-form__wb-field">
                <label htmlFor="safariOwnerLogo">Company Logo <span style={{ fontSize: '12px' }}>(.png, .jpg, jpeg)</span><span className="req">*</span>
                </label>
                <div className="file-upload">
                  <div className="file-select" name="logo">
                    <div className="file-select-button" id="fileName">Choose File</div>
                    <div className="file-select-name" id="noFile">{values.safariOwnerLogo?.name || 'No file selected'}</div>
                    <input
                      type="file"
                      id="safariOwnerLogo"
                      name="safariOwnerLogo"
                      onChange={e => handleFieldChange(e.target.files[0], 'safariOwnerLogo')}
                      onBlur={() => handleFieldTouched('safariOwnerLogo')}
                    />
                  </div>
                  {
                    validationErrors.safariOwnerLogo && touched.safariOwnerLogo
                      ? <p className="errors">{validationErrors.safariOwnerLogo}</p>
                      : null
                  }
                </div>
              </div>

              <div className="registration-form__wb-field" >
                <label htmlFor="name">Your Name  <span className="req">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={e => handleFieldChange(e.target.value, 'name')}
                  onBlur={() => handleFieldTouched('name')}
                />
                {
                  validationErrors.name && touched.name
                    ? <p className="errors">{validationErrors.name}</p>
                    : null
                }
              </div>


              <div className="registration-form__wb-field">
                <label htmlFor="position">Your Position  <span className="req">*</span>
                </label>
                <input
                  type="text"
                  id="position"
                  name="position"
                  value={values.position}
                  onChange={e => handleFieldChange(e.target.value, 'position')}
                  onBlur={() => handleFieldTouched('position')}
                />
                {
                  validationErrors.position && touched.position
                    ? <p className="errors">{validationErrors.position}</p>
                    : null
                }
              </div>


              <div className="registration-form__wb-field">
                <label htmlFor="email">Email Address <span className="req">*</span>
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={e => handleFieldChange(e.target.value, 'email')}
                  onBlur={() => handleFieldTouched('email')}
                />
                {
                  validationErrors.email && touched.email
                    ? <p className="errors">{validationErrors.email}</p>
                    : null
                }
              </div>
            </div>

            <div className="registration-form__first">

              <div className="registration-form__wb-field">
                <label htmlFor="certOfIncorporation">Certificate of Incorporation <span style={{ fontSize: '12px' }}>(.png, .jpg, jpeg)</span><span className="req">*</span>
                </label>
                <div className="file-upload" name="certificate_of_incorporation">
                  <div className="file-select">
                    <div className="file-select-button" id="fileName">Choose File</div>
                    <div className="file-select-name" id="noFile">{values.certOfIncorporation?.name || 'No file selected'}</div>
                    <input
                      type="file"
                      id="certOfIncorporation"
                      name="certOfIncorporation"
                      onChange={e => handleFieldChange(e.target.files[0], 'certOfIncorporation')}
                      onBlur={() => handleFieldTouched('certOfIncorporation')}
                    />
                  </div>
                  {
                    validationErrors.certOfIncorporation && touched.certOfIncorporation
                      ? <p className="errors">{validationErrors.certOfIncorporation}</p>
                      : null
                  }
                </div>
              </div>

              <div className="registration-form__wb-field" >
                <label htmlFor="password">Password  <span className="req">*</span>
                </label>
                <div style={{ position: 'relative' }}>
                  <input
                    name="password"
                    id="password"
                    type={inputType}
                    value={values.password}
                    onChange={e => handleFieldChange(e.target.value, 'password')}
                    onBlur={() => handleFieldTouched('password')}
                    className="password__input" />
                  <span className="password__shows" onClick={toggleInputType}>{inputType === 'password' ? <img src={showpwb} alt="show password"></img> : <img src={showpass} alt="hide password"></img>}</span>
                </div>
                {
                  validationErrors.password && touched.password
                    ? <p className="errors">{validationErrors.password}</p>
                    : null
                }
              </div>



              <div className="registration-form__wb-field">
                <label htmlFor="affiliateName">Affiliate Organization Name <span className="req">*</span>
                </label>
                <input
                  type="text"
                  name="affiliateName"
                  id="affiliateName"
                  placeholder="e.g. ATTA"
                  value={values.affiliateName}
                  onChange={e => handleFieldChange(e.target.value, 'affiliateName')}
                  onBlur={() => handleFieldTouched('affiliateName')}
                />
                {
                  validationErrors.affiliateName && touched.affiliateName
                    ? <p className="errors">{validationErrors.affiliateName}</p>
                    : null
                }
              </div>

              <div className="registration-form__wb-field">
                <label htmlFor="affiliateLink">Link to Affiliate Organization  <span className="req">*</span>
                </label>
                <input
                  type="text"
                  name="affiliateLink"
                  id="affiliateLink"
                  value={values.affiliateLink}
                  onChange={e => handleFieldChange(e.target.value, 'affiliateLink')}
                  onBlur={() => handleFieldTouched('affiliateLink')}
                />
                {
                  validationErrors.affiliateLink && touched.affiliateLink
                    ? <p className="errors">{validationErrors.affiliateLink}</p>
                    : null
                }
              </div>

              <div className="registration-form__wb-field">
                <label htmlFor="affiliateLogo">Affiliate Organization Logo <span style={{ fontSize: '12px' }}>(.png, .jpg, jpeg)</span><span className="req">*</span>
                </label>
                <div className="file-upload">
                  <div className="file-select">
                    <div className="file-select-button" id="fileName">Choose File</div>
                    <div className="file-select-name" id="noFile">{values.affiliateLogo?.name || 'No file selected'}</div>
                    <input
                      type="file"
                      id="affiliateLogo"
                      name="affiliateLogo"
                      onChange={e => handleFieldChange(e.target.files[0], 'affiliateLogo')}
                      onBlur={() => handleFieldTouched('affiliateLogo')}
                    />
                  </div>
                  {
                    validationErrors.affiliateLogo && touched.affiliateLogo
                      ? <p className="errors">{validationErrors.affiliateLogo}</p>
                      : null
                  }
                </div>
              </div>

              <div className="terms-container">
                <label className="container" htmlFor="terms">I agree to the <span className="registration-form__terms"><a onClick={showTerms}>Terms and Conditions </a></span> <span className="req">*</span>
                </label>
                <input
                  type="checkbox"
                  id="terms"
                  name="termsAccepted"
                  checked={values.termsAccepted}
                  onChange={e => handleFieldChange(e.target.checked, 'termsAccepted')}
                  onBlur={() => handleFieldTouched('termsAccepted')}
                />
                <span className="checkmark"></span>
              </div>
              {
                validationErrors.termsAccepted && touched.termsAccepted
                  ? <p className="errors">{validationErrors.termsAccepted}</p>
                  : null
              }

              <div className="registration-from ">
                <div className="registration-from__img"></div>
                <div className="registration-from__text">  <input type="submit" className="create_account" value="Create Account" disabled={loading} /></div>
              </div>
            </div>
          </div>
        </form>
      )}
      {terms && <Terms closeTerms={setTerm} />}
      {
        feedbackOpen && <Close
          error={error}
          close={closeMessage} />
      }
    </div >

  )
}



export default SafariOwner;

import SafariDetailsPage from './pages/SafariDetailsPage';
import AccommodationsPage from './pages/AccommodationListPage';
import SafariListPage from './pages/SafariListPage';
import AboutUs from './pages/AboutUsPage';
import SingleAccomodationPage from './pages/AccomodationDetailsPage';
import DestinationsPage from './pages/DestinationsPage';
import InfoPage from './pages/UsefulInfo';
import ContactPage from './pages/ContactPage';
import VerificationPage from './pages/VerificationPage';
import NotFoundPage from './pages/404';
import HomePage from './pages/HomePage';
import TermsAndconditions from './pages/TermsAndConditions';

export default [
  {
    path: "/",
    component: HomePage,
    exact: true
  },
  {
    path: "/safaris/:slug",
    component: SafariDetailsPage,
    exact: true
  },

  {
    path: "/safaris",
    component: SafariListPage,
    exact: true
  },
  {
    path: "/accommodation",
    component: AccommodationsPage,
    exact: true
  },
  {
    path: "/about-us",
    component: AboutUs,
    exact: true
  },
  {
    path: "/accommodation/:slug",
    component: SingleAccomodationPage,
    exact: true
  },
  {
    path: "/destinations",
    component: DestinationsPage,
    exact: true
  }, {
    path: "/useful-info",
    component: InfoPage,
    exact: true
  }, {
    path: "/contact",
    component: ContactPage,
    exact: true
  }, {
    path: "/verification",
    component: VerificationPage,
    exact: true
  }, {
    path: "/terms",
    component: TermsAndconditions,
    exact: true
  }, {
    path: "*",
    component: NotFoundPage,
    exact: false
  }
];

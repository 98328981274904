import React from 'react';
import FooterDestinations from './destinations/index';
import InfoSection from './info-section/InfoSection';
import Copyright from './copyright/Copyright';
import AfricaLogo from '../../images/africa-logo.svg';

const Footer = () => {
  const styles = {
    width: '32rem',
    height: '30rem',
    objectFit: 'contain',
    position: 'absolute',
    top: '-4rem',
    right: '0',
    zIndex: '-1',
  };

  return (
    <div style={{ position: 'relative' }}>
      {/* <FooterDestinations /> */}
      <InfoSection />
      <Copyright />
      <img style={styles} src={AfricaLogo} alt="Africa" />
    </div>
  );
};
export default Footer;

/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import ver from '../../../images/icons/ver.png';
import styles from './ToolTip.module.scss';
import { v4 as uuidv4 } from 'uuid';

const ToolTipIcon = ({ isSmall }) => {
  const uniqueId = uuidv4();

  return (
    <>
      <div className={styles.tick}>
        <div className={styles.tooltip}>
          <img
            data-tip="Verified by owner"
            data-for={`tooltip-${uniqueId}`}
            alt=""
            src={ver}
            className={isSmall ? styles.small : null}
          />
        </div>
      </div>
      <ReactTooltip
        effect='solid'
        id={`tooltip-${uniqueId}`}
        backgroundColor='#7fa73c'
      />
    </>
  );
}

export default ToolTipIcon;